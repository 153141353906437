import React from 'react';
import { motion } from 'framer-motion';
import { FaEnvelope, FaTelegramPlane, FaTwitter, FaGithub } from 'react-icons/fa';
import './Contact.css';

const Contact = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { type: "spring", stiffness: 100 }
    }
  };

  const titleAnimation = {
    initial: { y: -50, opacity: 0 },
    whileInView: { y: 0, opacity: 1 },
    viewport: { once: false, amount: 0.8 },
    transition: { duration: 0.8, type: "spring", stiffness: 100 },
  };

  return (
    <motion.div 
      className="bittech-contact"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h1 
        className="bittech-contact__title"
        {...titleAnimation}
      >
        Get in Touch
      </motion.h1>
      <div className="bittech-contact__content">
        <motion.div 
          className="bittech-contact__section bittech-contact__general"
          variants={itemVariants}
        >
          <motion.h2 {...titleAnimation}>Contact Us</motion.h2>
          <ul className="bittech-contact__info-list">
            <li>
              <FaEnvelope className="bittech-contact__icon" />
              <a href="mailto:networkbittech@gmail.com">networkbittech@gmail.com</a>
            </li>
            <li>
              <FaTelegramPlane className="bittech-contact__icon" />
              <a href="https://t.me/Bittechnetwork" target="_blank" rel="noopener noreferrer">@BitTech Network</a>
            </li>
            <li>
              <FaTwitter className="bittech-contact__icon" />
              <a href="https://twitter.com/antoine_bit" target="_blank" rel="noopener noreferrer">@bittechnetwork</a>
            </li>
            <li>
              <FaGithub className="bittech-contact__icon" />
              <a href="https://github.com/kukaloka1" target="_blank" rel="noopener noreferrer">bittechnetwork</a>
            </li>
          </ul>
        </motion.div>
        <motion.div 
          className="bittech-contact__section bittech-contact__business"
          variants={itemVariants}
        >
          <motion.h2 {...titleAnimation}>Business Enquiries</motion.h2>
          <p>Welcome to the future of tech innovation!</p>
          <p>Are you an investor looking for the next big thing in AI and blockchain? A developer eager to push the boundaries of what's possible? Or perhaps you're an indie hacker with a groundbreaking idea?</p>
          <p>At Bittech Network, we're always excited to collaborate with forward-thinking minds. Whether you're interested in our projects, need our expertise, or want to explore partnership opportunities, we're here to turn innovative ideas into reality.</p>
          <p>Let's create something extraordinary together!</p>
          <a href="mailto:networkbittech@gmail.com" className="bittech-contact__business-link">Reach out now: networkbittech@gmail.com</a>
        </motion.div>
      </div>
      <motion.div 
        className="bittech-contact__logo-container"
        variants={itemVariants}
      >
        {/* You can add your logo here if you want */}
      </motion.div>
    </motion.div>
  );
};

export default Contact;
