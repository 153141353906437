import React from 'react';
import { motion } from 'framer-motion';
import { FaPalette, FaCode, FaBitcoin, FaLightbulb, FaRocket, FaShieldAlt, FaTwitter } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';  // Importa useNavigate
import './Services.css';

const Services = () => {
  const navigate = useNavigate();  // Define navigate para la redirección

  const services = [
    {
      icon: <FaPalette />,
      title: "Web Design",
      description: "Creating stunning, responsive websites that captivate and engage your audience.",
      price: "$999",
    },
    {
      icon: <FaCode />,
      title: "Web Apps",
      description: "Developing powerful, scalable applications that drive business growth and efficiency.",
      price: "$1499",
    },
    {
      icon: <FaBitcoin />,
      title: "Crypto Solutions",
      description: "Integrating blockchain technology to enhance security and transparency in your operations.",
      price: "$1999",
    },
    {
      icon: <FaLightbulb />,
      title: "Tech Consultancy",
      description: "Providing expert guidance to navigate the complex landscape of emerging technologies.",
      price: "$499",
    },
    {
      icon: <FaRocket />,
      title: "Rapid Deployment",
      description: "Ensuring quick and efficient launches of your digital products and services.",
      price: "$799",
    },
    {
      icon: <FaShieldAlt />,
      title: "Cybersecurity",
      description: "Implementing robust security measures to protect your digital assets and data.",
      price: "$1299",
    },
    {
      icon: <FaPalette />,
      title: "Brand Design",
      description: "Crafting unique and impactful brand identities that resonate with your audience.",
      price: "$899",
    },
    {
      icon: <FaTwitter />,
      title: "AI Bots for X",
      description: "Boost your X presence with our AI-powered marketing solutions.",
      price: "$699",
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 }
    }
  };

  const titleAnimation = {
    initial: { y: -50, opacity: 0 },
    whileInView: { y: 0, opacity: 1 },
    viewport: { once: false, amount: 0.8 },
    transition: { duration: 0.8, type: "spring", stiffness: 100 },
  };

  // Función para manejar el clic en el botón
  const handleContactClick = () => {
    navigate('/contact');  // Redirige a la página de contacto
  };

  return (
    <motion.div 
      className="bittech-services"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.h1 
        className="bittech-services__title"
        {...titleAnimation}
      >
        Our Services
      </motion.h1>
      <motion.p 
        className="bittech-services__intro"
        variants={itemVariants}
      >
        Not just creating our own products - we're here to bring your ideas to life. Fast delivery, unique solutions, and robust architecture - that's what sets us apart.
      </motion.p>
      <motion.div 
        className="bittech-services__grid"
        variants={containerVariants}
      >
        {services.map((service, index) => (
          <motion.div 
            key={index} 
            className="bittech-services__card"
            variants={itemVariants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.div 
              className="bittech-services__icon"
              whileHover={{ rotate: 360 }}
              transition={{ duration: 0.5 }}
            >
              {service.icon}
            </motion.div>
            <motion.h2 className="bittech-services__card-title" {...titleAnimation}>
              {service.title}
            </motion.h2>
            <p className="bittech-services__description">{service.description}</p>
            <p className="bittech-services__price">Starting at {service.price}</p>
          </motion.div>
        ))}
      </motion.div>
      <motion.div 
        className="bittech-services__cta"
        variants={itemVariants}
      >
        <h2>Ready to level up your tech game?</h2>
        <p>From design to deployment, we're here to make your digital dreams a reality. Fast, unique, and robust - that's the Bittech way.</p>
        <motion.button 
          className="bittech-services__cta-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleContactClick}  // Maneja el clic
        >
          Get in Touch
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default Services;

