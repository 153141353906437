import React from "react";
import { Link } from "react-router-dom";
import { FaDiscord, FaTwitter, FaTwitch, FaCode } from "react-icons/fa";
import "./Footer.css";
import logo from "../../assets/images/logosolo.svg"; // Asegúrate de que la ruta sea correcta

const Footer = () => {
  return (
    <footer className="bittech-footer">
      <div className="bittech-footer__container">
        <div className="bittech-footer__logo-section"></div>
        <div className="bittech-footer__content">
          <div className="bittech-footer__links">
            <Link to="/about" className="bittech-footer__link">
              About
            </Link>
            <Link to="/projects" className="bittech-footer__link">
              Projects
            </Link>
            <Link to="/contact" className="bittech-footer__link">
              Contact
            </Link>
          </div>
          <div className="bittech-footer__social">
            <a
              href="https://discord.com/channels/1213577177254072320/1213577177874825369"
              target="_blank"
              rel="noopener noreferrer"
              className="bittech-footer__social-link"
            >
              <FaDiscord />
            </a>
            <a
              href="https://twitter.com/antoine_bit"
              target="_blank"
              rel="noopener noreferrer"
              className="bittech-footer__social-link"
            >
              <FaTwitter />
            </a>
            <a
              href="https://twitch.tv/yourchannel"
              target="_blank"
              rel="noopener noreferrer"
              className="bittech-footer__social-link"
            >
              <FaTwitch />
            </a>
          </div>
          <img
            src={logo}
            alt="Bittech Network Logo"
            className="bittech-footer__logo"
          />
        </div>
        <p className="bittech-footer__created-by">
          <FaCode /> This webpage was created by Bittech Network
        </p>
        <p className="bittech-footer__copyright">
          © 2024 Bittech Network. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
