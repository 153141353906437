import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './Games.css';

const Games = () => {
  const games = [
    {
      title: "Bittech Tetris",
      description: "Stack and clear lines in this classic puzzle game with a Bittech twist!",
      image: "tetris-preview.png", // You'll need to create this image
      link: "/games/tetris"
    },
    {
      title: "Space Invaders X",
      description: "Defend Earth from pixelated alien invaders in this retro shooter!",
      image: "space-invaders-preview.png", // You'll need to create this image
      link: "/games/space-invaders"
    },
    {
      title: "Neon Snake",
      description: "Guide your snake through a glowing cyber grid in this updated classic!",
      image: "snake-preview.png" // You'll need to create this image
    }
  ];

  return (
    <motion.div 
      className="bittech-games"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.h1 
        className="bittech-games__title"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Bittech Arcade
      </motion.h1>
      <motion.div 
        className="bittech-games__grid"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        {games.map((game, index) => (
          <motion.div 
            key={index} 
            className="bittech-games__card"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <img src={game.image} alt={game.title} className="bittech-games__image" />
            <h2 className="bittech-games__card-title">{game.title}</h2>
            <p className="bittech-games__description">{game.description}</p>
            <Link to={game.link} className="bittech-games__link">Play Now</Link>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Games;