import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Projects from './pages/Projects/Projects';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Games from './pages/Games/Games';
import Services from './pages/Services/Services';
import ScrollToTop from './utils/ScrollToTop'; // Importa el componente ScrollToTop
import './App.css';

// Componentes temporales para las otras páginas
const TempComponent = ({ name }) => (
  <div className="bittech-temp-page">
    <h1>{name}</h1>
    <p>This page is coming soon. Stay tuned for awesome {name.toLowerCase()} content!</p>
  </div>
);

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Añadir ScrollToTop para que funcione */}
      <div className="bittech-app">
        {/* Añadir el contenedor de flares aquí */}
        <div className="flare-container">
          <div className="flare flare-1"></div>
          <div className="flare flare-2"></div>
          <div className="flare flare-3"></div>
        </div>
        <Header />
        <main className="bittech-app__main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/games" element={<Games />} />
            <Route path="/services" element={<Services />} />
            <Route path="/privacy-policy" element={<TempComponent name="Privacy Policy" />} />
            <Route path="/terms-of-service" element={<TempComponent name="Terms of Service" />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

