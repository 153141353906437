import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/images/botonsendbot8.svg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const burgerRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    closeMenu();
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (burgerRef.current && !burgerRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="bittech-header">
      <div className="bittech-header__container">
        <Link to="/" className="bittech-header__logo-link">
          <img
            src={logo}
            alt="Bittech Network Logo"
            className="bittech-header__logo"
          />
        </Link>
        <nav
          className={`bittech-header__nav ${
            isMenuOpen ? "bittech-header__nav--open" : ""
          }`}
        >
          <ul className="bittech-header__nav-list">
            <li className="bittech-header__nav-item">
              <Link
                to="/"
                className="bittech-header__nav-link"
                onClick={closeMenu}
              >
                Home
              </Link>
            </li>
            <li className="bittech-header__nav-item">
              <Link
                to="/projects"
                className="bittech-header__nav-link"
                onClick={closeMenu}
              >
                Projects
              </Link>
            </li>
            <li className="bittech-header__nav-item">
              <Link
                to="/services"
                className="bittech-header__nav-link"
                onClick={closeMenu}
              >
                Services
              </Link>
            </li>
            <li className="bittech-header__nav-item">
              <Link
                to="/about"
                className="bittech-header__nav-link"
                onClick={closeMenu}
              >
                About
              </Link>
            </li>
            <li className="bittech-header__nav-item">
              <Link
                to="/contact"
                className="bittech-header__nav-link"
                onClick={closeMenu}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <button
          ref={burgerRef}
          className={`bittech-header__burger ${
            isMenuOpen ? "bittech-header__burger--open" : ""
          }`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <span className="bittech-header__burger-box">
            <span className="bittech-header__burger-inner"></span>
          </span>
        </button>
      </div>
    </header>
  );
};

export default Header;
